import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { scrollToPosition } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { isAnnualPayment } from 'api/businessLogic/quote';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import LoadRenewalWrapper from 'components/ApiRequestWrapper/LoadRenewalWrapper';
import SecurePaymentFooterBlock from 'components/Footer/SecurePaymentFooter';
import Layout from 'components/Layout';
import PaymentPage from 'components/Payment/PaymentPage';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { isQuoteSummaryValid } from 'helpers/quoteSummaryValidation';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { renewalRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { isRenewal, useQuote } from 'state/quote/quote';
import { useUser } from 'state/user/state';
import { CsHero, CsMeta } from 'types/contentStack';

type PaymentProps = {
  data: {
    csPayment: {
      meta: CsMeta;
      hero_renewal_annual: CsHero;
      hero_renewal_monthly: CsHero;
    };
  };
  location: Location & { state?: { detailsValid: boolean; hasAccount: boolean | null } };
};

export const query = graphql`
  query {
    csPayment {
      meta {
        meta_title
      }
      hero_renewal_annual {
        subheading
        heading
      }
      hero_renewal_monthly {
        subheading
        heading
      }
    }
  }
`;

const Payment: React.FC<PaymentProps & PolicyNumberProps> = ({
  data,
  location,
  policyNumber,
}) => {
  const quote = useQuote();

  const [user] = useUser();
  usePageTracking(data.csPayment.meta.meta_title, !!user?.isLoggedIn && !!quote);

  const { meta, hero_renewal_annual, hero_renewal_monthly } = quote
    ? replaceQuotePlaceholders(data.csPayment, quote)
    : data.csPayment;

  useEffect(() => {
    if (quote) {
      if (!isRenewal(quote) || quote.renewalStatus !== 'InProgress') {
        throw new Error('Returned quote does not have renewal status "InProgress"');
      } else if (!isQuoteSummaryValid(quote)) {
        navigate(renewalRoutes.quoteSummary(quote.quoteNumber));
      } else if (!location.state?.detailsValid) {
        navigate(renewalRoutes.checkYourDetails(quote.quoteNumber));
      }
    }
  }, [quote, location]);

  const paymentOption = quote?.policy.paymentOption;
  useEffect(() => {
    scrollToPosition(0);
  }, [paymentOption]);

  useCheckoutTracking(7, quote, 'Renewals');

  return (
    <>
      <Layout meta={meta} pageType="mtaAndRenewal">
        <LoadRenewalWrapper policyNumber={policyNumber}>
          {quote && location.state && (
            <>
              {isAnnualPayment(quote) ? (
                <HeroPlain
                  heading={hero_renewal_annual.heading}
                  subhead={hero_renewal_annual.subheading || undefined}
                />
              ) : (
                <HeroPlain
                  heading={hero_renewal_monthly.heading}
                  subhead={hero_renewal_monthly.subheading || undefined}
                />
              )}
              <PaymentPage quote={quote} hasAccount />
            </>
          )}
        </LoadRenewalWrapper>
      </Layout>
      <SecurePaymentFooterBlock />
    </>
  );
};

export default withPolicyNumber(Payment);
